import api from '@/helper/api'
import axios from 'axios'
// import phpajax from '@/helper/phpajax';

export const baseDataService = {
    async login(UserName,Password)
    {
        const res = await api.login(UserName,Password);
        return res;
    },
    async unsubscribe(Email)
    {
        const res = await api.unsubscribe(Email);
        return res;
    },
    async createSalesOrder(idsalsOrderHeader,idCustomerMaster,DetailsLines,additionalDetails){
        authenticateApi(); 
        const res = await api.createSalesOrder(idsalsOrderHeader,idCustomerMaster,DetailsLines,additionalDetails);
        return res;
    },
    async menu(){
        authenticateApi();
        try{
            const res = await axios.get(process.env.VUE_APP_API_SERVER +'menu.php');
            return res.data;
        }catch(e){
            //check if we have menu in application data and return that
            try{
                return JSON.parse(localStorage.getItem('userMenu'))
            }catch(err){
                throw e;
            }
            throw e;
        }
        
    },
    async allProducts(){
        authenticateApi();
        const res = await api.listTable("products",[],"Description");
        return res.records;
    },
    async products(){
         
        authenticateApi();
        try{
            const res = await axios.get(process.env.VUE_APP_API_SERVER +'products.php');        
            return res.data;

        }catch(e){
            try{
                 
                let allProducts = [];
                let allProductsId = JSON.parse(localStorage.getItem("productsid"));
                if(allProductsId && allProductsId.length){
                    for (let index = 0; index < allProductsId.length; index++) {
                        const c = allProductsId[index];
                        let p = JSON.parse(localStorage.getItem(c));
                        if(p && p.idproduct){
                            allProducts.push(p);
                        } 
                    } 
                } 
                return allProducts;
            }catch(err){
                return [];
            }
        }
    },
    
    async tags(){
        authenticateApi();
        const res = await api.listTable("tags","","Name");
        return res.records;
    },
    
    async listUsers(){
        authenticateApi();
        const res = await api.listTable("users","Active,eq,Y","email");
        return res.records;
    },
    async listUsersOnly(){
        authenticateApi();
        const res = await api.listTable("users","Active,eq,Y","email",-1,"idusers,email,Name,Sername,Role,SalesAreaArray");
        return res.records;
    },
    async getUserByEmail(EmailAddress){
        authenticateApi();
        const res = await api.listTable("users",["Active,eq,Y","email,eq,"+EmailAddress],"email");
        return res.records;
    },
    async categories(){
        authenticateApi();
        const res = await api.listTable("categories","isdeleted,eq,0","Name");
        return res.records;
    },
    async getCategories(id){
        authenticateApi();
        const res = await api.GetRow("categories",id);
        return res;
    },
    async updateCategories(id,NewValue){
        authenticateApi();
        const res = await api.UpdateRow("categories",id,NewValue);
        return res;
    },
    async createCategories(NewValue){
        authenticateApi();
        const res = await api.CreateRow("categories",NewValue);
        return res;
    },
    async listSalesReps(){
        authenticateApi();
        const res = await api.listTable("users",["Role,eq,sales_rep","Active,eq,Y"],
                                        "Name",-1,"idusers,email,Name,Sername,Role");
        return res.records;
    },
    async listSalesAreas(){
        authenticateApi();
        const res = await api.listTable("SalesAreas","","Name");
        return res.records;
    },
    async listActiveCustomers(){
        authenticateApi();
        const res = await api.listTable("customerMaster",["isDeleted,eq,0"],"Name");
        return res.records;
    },
    async listSalesRepCustomers(){
        authenticateApi();
        const res = await axios.get(process.env.VUE_APP_API_SERVER +'SalesRepCustomer.php');
        return res.data;
    },
    async listContactHeaders(){
        authenticateApi();
        const res = await api.listTable("crm_Contact_types",["isDeleted,eq,0"],"Sort");
        return res.records;
    },
    async listCrm_customerHeaderNote(CustomerID){
        authenticateApi();
        const res = await api.listTable("crm_customerHeaderNote",["isDeleted,eq,0",`idCustomerMaster,eq,${CustomerID}`],"idcrm_customerHeaderNote");
        return res.records;
    },
    async listCrm_customerHistory(CustomerID){
        authenticateApi();
        const res = await api.listTable("crm_customerHistory",["isDeleted,eq,0",`idCustomerMaster,eq,${CustomerID}`],"VisitDate,desc");
        return res.records;
    },
    async listCustomer_Contacts(CustomerID){
        authenticateApi();
        const res = await api.listTable("crm_customer_Contacts",["isDeleted,eq,0",`idCustomerMaster,eq,${CustomerID}`],"Sort");
        return res.records;
    },
    async listAllCustomer_Contacts(){
        authenticateApi();
        const res = await axios.get(process.env.VUE_APP_API_SERVER +'SalesRepContacts.php');
        return res.data;
    },
    async listContactsBook(){
        authenticateApi();
        const res = await api.listTable("crm_customer_Contacts",["isDeleted,eq,0",`idCustomerMaster,eq,0`],"GroupingName");
        return res.records;
    },
    async listCrm_customerAddress(CustomerID){
        authenticateApi();
        const res = await api.listTable("crm_customerAddress",["isDeleted,eq,0",`idCustomerMaster,eq,${CustomerID}`],"Sort");
        return res.records;
    },
    // async listAllCrm_customerAddress(){
    //     authenticateApi();
    //     const res = await api.listTable("crm_customerAddress",["isDeleted,eq,0"],"Sort");
    //     return res.records;
    // },
    async listCustomerOrders(idCustomerMaster){
        authenticateApi();
        const res = await api.listCustomerOrders(idCustomerMaster);
        return res;
    },
    async listSalesOrderDetails(idSalsOrderHeader){
        authenticateApi();
        const res = await api.listSalesDetails(idSalsOrderHeader);
        return res;
    },
    async listNewActiveByArea(CustomerCategory,OrderStats){
        authenticateApi();
        const res = await api.listNewActiveByArea(CustomerCategory,OrderStats);
        return res;
    },
    async setOrderConfirm(aAllSalesOrderId,ShipDate){
        authenticateApi();
        const res = await api.setOrderConfirm(aAllSalesOrderId,ShipDate);
        return res;
    },
    async confirmShipment(aAllSalesOrderId){
        authenticateApi();
        const res = await api.confirmShipment(aAllSalesOrderId);
        return res;
    },
    async rejectLine(allDetails,comment){
        authenticateApi();
        const res = await api.rejectLine(allDetails,comment);
        return res;
    },
    async listConfirmedByArea(CustomerCategory,OrderStats,ShipDate){
        authenticateApi();
        const res = await api.listConfirmedByArea(CustomerCategory,OrderStats,ShipDate);
        return res;
    },
    async listCustomerTypes(idproduct){
        authenticateApi();
        const res = await api.listTable("customerTypes",['isDeleted,eq,0'],"Sort");
        return res.records;
    },
    async listProductImages(idproduct){
        authenticateApi();
        const res = await api.listTable("productImages",[`idproduct,eq,${idproduct}`],"ImageName");
        return res.records;
    },
    async listCrm_autoFilter(){
        authenticateApi();
        const res = await api.listTable("crm_autoFilter",[`isDeleted,eq,0`],"Name");
        return res.records;
    },
    async listCrm_settings(){
        authenticateApi();
        const res = await api.listTable("crm_settings",[`isDeleted,eq,0`],"Name");
        return res.records;
    },
    async listSales_grouping(){
        authenticateApi();
        const res = await api.listTable("sales_grouping",[`isDeleted,eq,0`],"Sort");
        return res.records;
    },
    async deleteProductImages(idProductImages){
        authenticateApi();
        const res = await api.DeleteRow("productImages",idProductImages);
        return res;
    },
    async toggleProductImageActive(idproduct,idProductImages){
        authenticateApi();
        const res = await api.toggleProductImageActive(idproduct,idProductImages);
        return res;
    },
    async listProductNoImages(){
        authenticateApi();
        const res = await api.listProductNoImages();
        return res;
    },
    async listProductsWithImageCount(){
        authenticateApi();
        const res = await api.listProductsWithImageCount();
        return res;
    },
    async listPlantType(){
        authenticateApi();
        const res = await api.listTable("plantType",["isDeleted,eq,0"],"Name");
        return res.records;
    },
    async deleteUsers(userID){
        authenticateApi();
        const res = await api.DeleteRow("users",userID);
        return res;
    },
    async reportDetailsNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints){
        authenticateApi();
        const res = await api.reportDetailsNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints);
        return res;
    },
    async reportSummeryNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints){
        authenticateApi();
        const res = await api.reportSummeryNote(idCustomerMaster,CustomerCategoryArray,startDate,endDate,OnlyShowCustomerComplaints);
        return res;
    },
    async reportDetailsContacts(idCustomerMaster,CustomerCategoryArray,startDate,endDate){
        authenticateApi();
        const res = await api.reportDetailsContacts(idCustomerMaster,CustomerCategoryArray,startDate,endDate);
        return res;
    },
    async reportFullDetailsContacts(idCustomerMaster,CustomerCategoryArray,CustomerType,ContactType){
        authenticateApi();
        const res = await api.reportFullDetailsContacts(idCustomerMaster,CustomerCategoryArray,CustomerType,ContactType);
        return res;
    },
    async reportSalesOrderDetails(idCustomerMaster,CustomerCategoryArray,UserList,CustomerType,startDate,endDate){
        authenticateApi();
        const res = await api.reportSalesOrderDetails(idCustomerMaster,CustomerCategoryArray,UserList,CustomerType,startDate,endDate);
        return res;
    },
    async getEmailContacts(autoFilter,saleArea,bulkChecked,ContactType,CustomerType){
        authenticateApi();
        const res = await api.getEmailContacts(autoFilter,saleArea,bulkChecked,ContactType,CustomerType);
        return res;
    },
    async sendEmail(subject,to,body,ImageList,AVFile ,AVFileName){
        authenticateApi();
        const res = await api.sendEmail(subject,to,body,ImageList,AVFile,AVFileName);
        return res;
    },
    async updateSalesOrderHeader(idSalesOrderHeader,OrderStats){
        authenticateApi();
        const res = await api.updateSalesOrderHeader(idSalesOrderHeader,OrderStats);
        return res;
    
    }
}
let TableList =["users","products","tags","customerMaster",
                "salsOrderHeader","salesRepToCustomer","salesOrderDetails",
                "SalesAreas","crm_Contact_types","crm_customerHeaderNote",
                "crm_customerHistory","crm_customer_Contacts","crm_customerAddress","productImages",
                "customerTypes","crm_autoFilter","crm_settings","sales_grouping",
                "plantType"];

TableList.forEach(table => {
    let cap= table[0].toUpperCase()+table.substring(1);
    baseDataService[`get${cap}`] = async function(id){
        authenticateApi();
        const res = await api.GetRow(table,id);
        return res;
    }
    baseDataService[`update${cap}`] = async function(id,NewValue){
        authenticateApi();
        const res = await api.UpdateRow(table,id,NewValue);
        return res;
    },
    baseDataService[`create${cap}`] = async function(NewValue){
        authenticateApi();
        const res = await api.CreateRow(table,NewValue);
        return res;
    }
    
});

async function authenticateApi() {
    let token= localStorage.getItem('token');
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}