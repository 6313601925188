<template>
<div>
  <nav class="navbar navbar-expand-sm bg-dark navbar-dark d-none d-md-block d-lg-block d-xl-block d-xxl-block">
    <div class="container-fluid">
      <ul class="navbar-nav nav-pills" v-if="userMenu && userMenu[0] && userMenu[0].items">
        <li class="nav-item pr-3">
          <img src="~@/assets/logo_small.webp" class="headerImg"/>
        </li>
        <li class="nav-item pt-2-5">
          <router-link to="/" :class="{'nav-link':true, 'active':isActive('/')}">Home</router-link> 
        </li>
        
        <li class="nav-item pt-2-5" v-for="(item, index) in userMenu[0].items" :key="item.heading">
          <router-link :to="menuHeading(index)" :class="{'nav-link':true, 'active':isActive(menuHeading(index),item)}">{{item.heading}}</router-link> 
        </li> 
        <li class="nav-item pt-2-5">
          <router-link to="/logout" class="nav-link">Logout</router-link> 
        </li>
      </ul>
    </div>
  </nav>
  <nav class="navbar navbar-expand-sm bg-dark navbar-dark d-block d-sm-none">
     
      <ul class="nav">
        <li class="nav-item pt-2-5">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" aria-label="Toggle navigation" @click="toggleMobile=!toggleMobile">
            <span class="navbar-toggler-icon"></span>
          </button>  
        </li>
        <li class="nav-item pr-3">
          <img src="~@/assets/logo_small.webp" class="headerImg"/>
        </li> 
         
      </ul>
     
  </nav>
  <b-offcanvas title="Menu" :modelValue="toggleMobile" @hide="toggleMobile=false">
    <div class="container-fluid">
      <ul class="navbar-nav nav-pills" v-if="userMenu && userMenu[0] && userMenu[0].items">
        <li class="nav-item pr-3">
          <img src="~@/assets/logo_small.webp" class="headerImg"/>
        </li>
        <li class="nav-item pt-2-5">
          <router-link to="/" :class="{'nav-link':true, 'active':isActive('/')}">Home</router-link> 
        </li>
        
        <li class="nav-item pt-2-5" v-for="(item, index) in userMenu[0].items" :key="item.heading">
          <router-link :to="menuHeading(index)" :class="{'nav-link':true, 'active':isActive(menuHeading(index),item)}" @click="toggleMobile = false">{{item.heading}}</router-link> 
        </li> 
        <li class="nav-item pt-2-5">
          <router-link to="/logout" class="nav-link">Logout</router-link> 
        </li>
        <li class="nav-item pt-2-5">
          <router-link to="/Update" class="nav-link">Force Update</router-link> 
        </li>
      </ul>
    </div>
  </b-offcanvas>
</div>
</template>

<script> 
export default {
  name:"topBar",
  computed: {
    userMenu() {
      return this.$store.state.auth.userMenu;
    },
    FullUserName() {
      return this.$store.state.auth.FullUserName;
    },
  },
  watch:{
    $route (to, from){
        this.toggleMobile = false;
        if(to && to.path){
          this.currentUrl=to.path;
        }
    }
  },
  data() {
    return {
      toggleMobile:false,
      currentUrl:"/"
    };
  },
  methods:{ 
    menuHeading(index){
      return `/menu/0/${index}`
    },
    isActive(urlRout,MenuHeadingItem){
      if(this.currentUrl==urlRout){
        return true;
      }
      if(this.currentUrl.startsWith(urlRout) && urlRout.length>4){
        return true;
      }
      if(MenuHeadingItem && MenuHeadingItem.items && MenuHeadingItem.items.length>0 ){
        for(let i = 0 ;i<MenuHeadingItem.items.length;i++){
          const subItem = MenuHeadingItem.items[i];
          
          if(this.currentUrl.startsWith(subItem.url) && this.currentUrl.length>4){
            return true;
          }
        }
      }

      return false;
    }
  } 
}
</script>
<style>
.headerImg{
  max-width: 80px;
  min-width: 25px;
}
.pt-2-5, .pt-2-5 {
    padding-top: 0.8rem!important;
}
</style>